import React from "react";

const Footer = () => {
    return (
        <footer id="footer" className="footer-app">
        <div className="container-wrap bg-footer-color">
          <div className="container">
            <div className="footer-inner">
              <div className="row wow fadeInUp">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget footer-widget widget-about">
                    <a href="#" className="footer-logo"><img src="assets/img/main-logo.png" alt="briezi" /></a>
                    <p>
                      The one stop solution platform to save insurance policy details of you and your family members across life, health and general Insurance
                    </p>

                    {/* <h4 className="footer-title">Social</h4>
                                <ul className="social-share-link">
                                    <li><a href="#" className="share_facebook"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#" className="share_twitter"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#" className="share_pinterest"><i className="fab fa-pinterest-p"></i></a></li>
                                    <li><a href="#" className="share_linkedin"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul> */}


                  </div>
                  {/* /.widget footer-widget */}
                </div>
                {/* /.col-lg-3 col-md-6 col-sm-6 */}
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget footer-widget widget-contact">
                    <h3 className="widget-title">Address</h3>

                    <ul className="widget-contact-info">
                      <li><i className="ei ei-icon_pin_alt"></i>#77/A, 9th main, RPC Layout, Vijayanagar, Bangalore - 560 104</li>

                    </ul>


                  </div>
                  {/* /.widget footer-widget */}
                </div>
                {/* /.col-lg-3 col-md-6 col-sm-6 */}

                <div className="col-lg-2 col-md-6 col-sm-6">
                  <div className="widget footer-widget">
                    <h3 className="widget-title">Company</h3>
                    <ul className="footer-menu">
                      <li><a href="terms">Terms of Service</a></li>
                      <li><a href="privacy">Privacy Policy</a></li>
        {/*               <li><a href="#">Contact Us</a></li>
                      <li><a href="#">We are hiring</a></li> */}

                    </ul>
                  </div>
                  {/* /.widget footer-widget */}
                </div>
                {/* /.col-lg-3 col-md-6 col-sm-6 */}

                <div className="col-lg-2 col-md-6 col-sm-6">
                  <div className="widget footer-widget">
                    <h3 className="widget-title">Support</h3>

                    <ul className="footer-menu">
                      <li><a href="mailto:support@briezi.com">support@briezi.com</a></li>
                    </ul>
                    <span id="siteseal"></span>
                  </div>
                  {/* /.widget footer-widget */}
                </div>
                {/* /.col-lg-3 col-md-6 col-sm-6 */}
              </div>
              {/* /.row */}
             
            </div>{/* /.footer-inner */}

            <div className="site-info">
              <div className="copyright text-center">
                <p>© 2022 All Rights Reserved by <a href="#" target="_blank">Briezi</a>
                </p>
              </div>
            
            </div>
          </div>{/* /.container */}


        </div>
        {/* /.container-wrap */}
      </footer>
    )
}

export default Footer