import React from 'react';
import ReactDOM from 'react-dom';
import {
  Router,
  Switch,
  Route
} from 'react-router-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import createBrowserHistory from 'history/createBrowserHistory'
import Terms from './Terms';
import Privacy from './privacy';
import Verify from './Verify';
export const history = createBrowserHistory()

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Switch>
        <Route path="/" render={() => <App />} exact />
        <Route path="/terms" render={() => <Terms />} exact />
        <Route path="/privacy" render={() => <Privacy />} exact />
        <Route path="/privacypolicy" render={() => <Privacy />} exact />
        <Route path="/Verify/:token" render={() => <Verify />} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
