
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useState } from 'react';
import Lottie from "lottie-react";
import groovyWalkAnimation from "./webtext.json";

function App() {
  
  return (
    <>
      <a href="#main_content" data-type="section-switch" className="return-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>
      <div className="page-loader">
        <div className="loader">
          {/* Loader */}
          <div className="blobs">
            <div className="blob-center"></div>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"></div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
          </svg>

        </div>
      </div>{/* /.page-loader */}
      <div id="main_content">
    <Header />


        <section className="banner banner-eight herobg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="banne-content-wrapper-eight">

                  <h4 className="banner-title wow fadeInUp" data-wow-delay="0.3s">
                  Insurance made easy with Briezi
                  <br></br>
                    {/* <span>Insurance Premium Finance</span> */}
                      <Lottie animationData={groovyWalkAnimation} loop={true} />
                  </h4>

                
                  <p className="description wow fadeInUp" data-wow-delay="0.5s">
                  
                  </p>



                </div>
                {/* /.banne-content-wrapper-six */}
              </div>
              {/* /.col-lg-6 */}

              <div className="col-lg-7">
                <div className="banner-six-promo-image text-right">
                  <img src="assets/img/heroimage.png" className="wow pixZoomIn" alt="briezi" />

            
                </div>
                {/* /.banner-six-promo-image */}
              </div>
              {/* /.col-lg-6 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
 
        </section>

{/* About */}

<section className="featured-six">
    <div className="container">
        <div className="section-title style-three text-center">
            <h2 className="title wow pixFadeUp" data-wow-delay="0.2s">
                Why Briezi?
            </h2>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
                <div className="feature-image-wrapper wow pixFadeLeft">
                    <img src="media/feature/tablet.png" alt=""/>
                </div>
            </div>
            <div className="col-lg-7 col-md-7">
                <div className="saaspik-icon-box-wrapper style-six wow pixFadeRight" data-wow-delay="0.4s">
                    <div className="saaspik-icon-box-icon">
                        <img src="media/feature/61.png" alt="icon"/>
                    </div>
                    <div className="pixsass-icon-box-content">
                        <h3 className="pixsass-icon-box-title">
                            <a href="#">Digital  Process</a>
                        </h3>
                    </div>
                </div>
                <div className="saaspik-icon-box-wrapper style-six wow pixFadeRight" data-wow-delay="0.5s">
                    <div className="saaspik-icon-box-icon">
                        <img src="media/feature/62.png" alt="icon"/>
                    </div>
                    <div className="pixsass-icon-box-content">
                        <h3 className="pixsass-icon-box-title">
                            <a href="#">Simple & Secure Process</a>
                        </h3>
                    </div>
                </div>
                <div className="saaspik-icon-box-wrapper style-six wow pixFadeRight" data-wow-delay="0.6s">
                    <div className="saaspik-icon-box-icon">
                        <img src="media/feature/63.png" alt="icon"/>
                    </div>
                    <div className="pixsass-icon-box-content">
                        <h3 className="pixsass-icon-box-title">
                            <a href="#">
                                Digital  Process
                            </a>
                        </h3>
                    </div>
                </div>
                <div className="saaspik-icon-box-wrapper style-six wow pixFadeRight" data-wow-delay="0.7s">
                    <div className="saaspik-icon-box-icon">
                        <img src="media/feature/61.png" alt="icon"/>
                    </div>
                    <div className="pixsass-icon-box-content">
                        <h3 className="pixsass-icon-box-title">
                            <a href="#">
                                Simple & Secure Process
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="down-bg">
        <img src="media/background/down-bg.png" alt="down-bg"/>
    </div>
</section>


{/* app downloads */}

<div className="marketing-logo-carousel" data-bg-image="media/background/marketing_bg.jpg">
    <div className="container">

        <div className="section-title style-six text-center">
            <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                <span> Nationwide </span>
                Happy Customer
            </h2>
            {/* <p className="wow pixFadeUp" data-wow-delay="0.5s">
                Tomfoolery pukka chap I'm telling wellies James Bond william<br/> chinwag Charles.
            </p> */}
        </div>
        <div className="countup-wrapper">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="fun-fact style-two wow pixFadeUp" data-wow-delay="0.3s">
                        <div className="counter">
                            <h4 className="count" data-counter="1500">1500 </h4>
                            <span>+</span>
                        </div>
                        <p>App Downloads</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="fun-fact style-two wow pixFadeUp" data-wow-delay="0.5s">
                        <div className="counter">
                            <h4 className="count" data-counter="10000">10000 </h4>
                            <span>+</span>
                        </div>
                        <p>Premium Finance</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="fun-fact style-two wow pixFadeUp" data-wow-delay="0.7s">
                        <div className="counter">
                            <h4 className="count" data-counter="10000">10000 </h4>
                            <span>+</span>
                        </div>
                        <p>Loan  on Insurance</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="fun-fact style-two wow pixFadeUp" data-wow-delay="0.9s">
                        <div className="counter">
                            <h4 className="count" data-counter="300">300 </h4>
                            <span>K+</span>
                        </div>
                        <p>Policy Assignment</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



        <section className="app-tabs ">

          <div className="container-wrap bg-color-one">
            <div className="container">
              <div id="pix-tabs" className="tabs-three">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="download-tab-content">
                      <div className="section-title style-five">

                        <h2 className="title wow fadeInUp" data-wow-delay="0.3s">Insurance Premium Finance</h2>
                        <h3 className="sub-title wow fadeInUp">How it works</h3>
                        {/* <p className="wow fadeInUp" data-wow-delay="0.5s">
                                            Some dodgy chav bugger all mate pukka bonnet jolly good codswallop that bog.!
                                        </p> */}
                      </div>
                      <ul id="pix-tabs-nav" style={{ pointerEvents: 'none' }} className="wow fadeInUp" data-wow-delay="0.7s">
                        <li><a href="javascript:void(0)"><i className="ei ei-icon_adjust-horiz"></i>Select loan and
                          EMI amount</a>
                        </li>
                        <li><a href="javascript:void(0)"><i className="ei ei-icon_creditcard"></i> Complete KYC</a></li>
                        <li><a href="javascript:void(0)"><i className="ei ei-icon_wallet_alt"></i> Money in
                          your account</a></li>
                      </ul> {/* tabs-nav */}
                    </div>
                    {/* /.download-tab-content */}
                  </div>
                  {/* /.col-lg-5 */}
                  <div className="col-lg-7">
                    <div className="tabs-content-wrapper">
                      <div id="pix-tabs-content" className="job-board-tabs-content wow fadeIn">
                        <div id="feature_tab1" className="content">
                          <div className="tab-image">
                            <img src="media/tabs/8.jpg" alt="briezi tab" />
                          </div>
                          {/* /.tab-image */}
                        </div>
                        <div id="feature_tab2" className="content">
                          <div className="tab-image">
                            <img src="assets/img/IPF.png" alt="briezi tab" />
                          </div>
                          {/* /.tab-image */}
                        </div>
                      </div> {/* tabs-content */}

                      <div className="tab-bg-shape-wrapper">
                        
                       
                      </div>
                    </div>
                    {/* /.tabs-content-wrapper */}

                  </div>
                  {/* /.col-lg-7 */}
                </div>
                {/* /.row */}
              </div>
            </div>
            {/* /.container */}
          </div>
          {/* /.container-wrap */}

        </section>


        <section id="app_image_content">
          <div className="container">
            <div className="app-image-content-wrapper-one">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="app-download-image">
                    <img src="assets/img/LOI.png" className="wow fadeInDown" alt="saaspik" />
                  </div>
                  {/* /.animaated-elements-three */}
                </div>
                {/* /.col-lg-5 */}

                <div className="col-lg-7">
                  <div className="image-content-three app-content-wrap pl-85">
                    <div className="section-title">
                      <h2 className="title wow fadeInUp">
                        Loan On Insurance
                      </h2>

                      <p className="wow fadeInUp" data-wow-delay="0.3s">
                      Getting a loan against your saving policy is made easy by Briezi
                      </p>
                      <ul className="list-items list-icon-arrow wow fadeInUp" data-wow-delay="0.4s">
                        <li>Instant liquidity</li>
                        <li>Low interest rate</li>
                        <li>Paperless</li>
                      </ul>
                    </div>
                    <a href="https://play.google.com/store/apps/details?id=com.briezi.customer" className="app-btn-two btn-light wow flipInX" style={{marginRight: 10}} data-wow-delay="0.5s">
                      <i className="fi flaticon-google-play"></i>
                      <span className="btn-text">
                        <span className="text-top">Get it on</span>
                        <span>Google Play</span>
                      </span>
                    </a>
                    <a href="https://apps.apple.com/us/app/briezi/id1585491694" className="app-btn-two btn-light wow flipInX" data-wow-delay="0.5s">
                      <i className="fab fa-app-store-ios"></i>
                      <span className="btn-text">
                        <span className="text-top">Download on the</span>
                        <span>App Store</span>
                      </span>
                    </a>
                  </div>
                  {/* /.image-content-three */}
                </div>
                {/* /.col-lg-7 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.job-image-content-wrapper-one */}


          </div>
          {/* /.container */}
        </section>

         <section className="app-tabs ">
          <div className="container-wrap bg-color-one">
            <div className="container">
              <div id="pix-tabs" className="tabs-three">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="download-tab-content">
                      <div className="section-title style-five">

                        <h2 className="title wow fadeInUp" data-wow-delay="0.3s">Policy Assignment</h2>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">
                      Surrendering the policy will hamper the policy benefits, through assignment you will be benefited with much higher or better than the surrender value
                      </p>
                      <ul className="list-items list-icon-arrow wow fadeInUp" data-wow-delay="0.4s">
                        <li>Instant liquidity</li>
                        <li>Life coverage</li>
                        <li>Paperless</li>
                      </ul>

                      </div>

                    </div>

                  </div>
                  {/* /.col-lg-5 */}
                  <div className="col-lg-7">
                    <div className="tabs-content-wrapper">
                      <div id="pix-tabs-content" className="job-board-tabs-content wow fadeIn">
                        <div id="feature_tab1" className="content">
                          <div className="tab-image">
                            <img src="media/tabs/8.jpg" alt="briezi tab" />
                          </div>
                          {/* /.tab-image */}
                        </div>
                        <div id="feature_tab2" className="content">
                          <div className="tab-image">
                            <img src="assets/img/PA.png" alt="briezi tab" />
                          </div>
                          {/* /.tab-image */}
                        </div>
                      </div> {/* tabs-content */}

                      <div className="tab-bg-shape-wrapper">
                        
                       
                      </div>
                    </div>
                    {/* /.tabs-content-wrapper */}

                  </div>
                  {/* /.col-lg-7 */}
                </div>
                {/* /.row */}
              </div>
            </div>
            {/* /.container */}
          </div>
</section>
     


        <section className="pricing">
          <div className="container">
            <div className="section-title text-center">
              {/* <h3 className="sub-title wow pixFadeUp">Pricing Plan</h3> */}
              <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                Why Choose Briezi
              </h2>
            </div>


            <div className="row advanced-pricing-table no-gutters wow pixFadeUp" data-wow-delay="0.5s">

              <div className="col-lg-4">
                {/* <div className="pricing-table br-left"> */}
                <div className="pricing-table style-two wow pixFadeLeft price-table" data-wow-delay="0.5s" style={{padding:"20px 40px"}}>
                  <div className="pricing-header pricing-amount" style={{ paddingBottom: 0 }}>
                    <div className="annual_price">
                      <h2 className="price">Briezi</h2>
                    </div>
                    {/* /.annual_price */}

                    <div className="monthly_price">
                      <h2 className="price">Briezi</h2>
                    </div>
                    {/* /.monthly_price */}

                  </div>
                  {/* /.pricing-header */}

                  <ul className="price-feture">
                    <li className="have">Store policy</li>
                    <li className="have">Know your policy</li>
                    <li className="have">Payments</li>
                    <li className="have">Loans</li>
                    <li className="have">Assignments</li>
                    <li className="have">Ease to use</li>
                    <li className="have">Share policy</li>
                  </ul>


                </div>
                {/* /.pricing-table */}
              </div>
              {/* /.col-lg-4 */}

              <div className="col-lg-4">
                <div className="pricing-table color-two">
                  <div className="pricing-header pricing-amount" style={{ paddingBottom: 0 }}>
                    <div className="annual_price">
                      <h2 className="price">Digi Locker</h2>
                    </div>
                    {/* /.annual_price */}

                    <div className="monthly_price">
                      <h2 className="price">Digi Locker</h2>
                    </div>
                    {/* /.monthly_price */}


                  </div>
                  {/* /.pricing-header */}

                  <ul className="price-feture">
                    <li className="have">Store policy</li>
                    <li className="not">Know your policy</li>
                    <li className="not">Payments</li>
                    <li className="not">Loans</li>
                    <li className="not">Assignments</li>
                    <li className="not">Ease to use</li>
                    <li className="not">Share policy</li>
                  </ul>


                </div>
                {/* /.pricing-table */}
              </div>
              {/* /.col-lg-4 */}

              <div className="col-lg-4">
                <div className="pricing-table color-three">

                  <div className="pricing-header pricing-amount" style={{ paddingBottom: 0 }}>
                    <div className="annual_price">
                      <h2 className="price">eIA</h2>
                    </div>
                    {/* /.annual_price */}

                    <div className="monthly_price">
                      <h2 className="price">eIA</h2>
                    </div>
                    {/* /.monthly_price */}

                  </div>
                  {/* /.pricing-header */}

                  <ul className="price-feture">
                    <li className="have">Store policy</li>
                    <li className="not">Know your policy</li>
                    <li className="have">Payments</li>
                    <li className="not">Loans</li>
                    <li className="not">Assignments</li>
                    <li className="not">Ease to use</li>
                    <li className="have">Share policy</li>
                  </ul>

                </div>
                {/* /.pricing-table */}
              </div>
              {/* /.col-lg-4 */}


            </div>
            {/* /.advanced-pricing-table */}
          </div>
          {/* /.container */}



          <div className="scroll-circle wow pixFadeLeft">
            <img src="media/background/circle8.png" data-parallax='{"y" : 130}' alt="circle" />
          </div>
        </section>

       
{/* Team */}
<section className="teams-six">
            <div className="container">
                <div className="section-title style-six text-center">
                    <h2 className="title wow fadeInUp" data-wow-delay="0.2s">
                        The Team Behind
                    </h2>
                </div>
                <div className="team-slider-wrapper">
                    <div className="swiper-container wow fadeInUp" data-wow-delay="0.6s" id="team-slider" data-speed="700" data-autoplay="5000" data-loop={true} data-perpage="4" data-space="50" data-breakpoints='{"1024": {"slidesPerView": 3}, "768": {"slidesPerView": 2}, "480": {"slidesPerView": 1}}'>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="team-member-four wow fadeInUp" data-wow-delay="0.04s">
                                    <div className="member-avater">
                                        <img src="team/Vinaybabu.jpg" alt="avater"/>
                                    </div>
                                    <div className="team-info">
                                      <div className='row'>
                                        <div className='col-lg-9 col-md-9 col-sm-9 text-left'>
                                        <h3 className="name">Vinay Babu</h3>
                                        <h4 className="job">Founder</h4>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-3'>
                                        <a href="https://in.linkedin.com/in/vinay-babu-56a2a52a" target={'_blank'} rel="noreferrer">
                                          <img style={{height:24}} src="assets/img/linkedin.png"/>
                                        </a> 
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="team-member-four wow fadeInUp" data-wow-delay="0.04s">
                                    <div className="member-avater">
                                        <img src="team/Saravana.jpg" alt="avater"/>
                                    </div>
                                    <div className="team-info">
                                      <div className='row'>
                                        <div className='col-lg-9 col-md-9 col-sm-9 text-left'>
                                        <h3 className="name">Saravanakumar</h3>
                                        <h4 className="job">Co-Founder</h4>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-3'>
                                        <a href="https://in.linkedin.com/in/saravanadev" target={'_blank'} rel="noreferrer">
                                          <img style={{height:24}} src="assets/img/linkedin.png"/>
                                        </a> 
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="team-member-four wow fadeInUp" data-wow-delay="0.04s">
                                    <div className="member-avater">
                                        <img src="team/Karthi.jpg" alt="avater"/>
                                    </div>
                                    <div className="team-info">
                                      <div className='row'>
                                        <div className='col-lg-9 col-md-9 col-sm-9 text-left'>
                                        <h3 className="name">Karthik Dev M</h3>
                                        <h4 className="job">Team Lead</h4>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-3'>
                                        <a href="https://in.linkedin.com/in/karthikdevm" target={'_blank'} rel="noreferrer">
                                          <img style={{height:24}} src="assets/img/linkedin.png"/>
                                        </a> 
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="team-member-four wow fadeInUp" data-wow-delay="0.04s">
                                    <div className="member-avater">
                                        <img src="team/Anusha.jpg" alt="avater"/>
                                    </div>
                                    <div className="team-info">
                                      <div className='row'>
                                        <div className='col-lg-9 col-md-9 col-sm-9 text-left'>
                                          <h3 className="name">Anusha A M</h3>
                                          <h4 className="job">Senior QA Engineer</h4>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-3'>
                                          <a href="https://in.linkedin.com/in/anusha-am-2065a6235" target={'_blank'} rel="noreferrer">
                                            <img style={{height:24}} src="assets/img/linkedin.png"/>
                                          </a> 
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="team-member-four wow fadeInUp" data-wow-delay="0.04s">
                                    <div className="member-avater">
                                        <img src="team/Lakshmeesha.jpg" alt="avater"/>
                                    </div>
                                    <div className="team-info">
                                      <div className='row'>
                                        <div className='col-lg-9 col-md-9 col-sm-9 text-left'>
                                        <h3 className="name">Lakshmeesha N</h3>
                                        <h4 className="job">UI/UX designer</h4>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-3'>
                                        <a href="https://in.linkedin.com/in/lakshmeesha-n" target={'_blank'} rel="noreferrer">
                                          <img style={{height:24}} src="assets/img/linkedin.png"/>
                                        </a> 
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
           
                   </section>


{/* partner section */}
<section className="client-logo-list">
            <div className="container">
                <div className="section-title style-five">
                       <h2 className="title wow pixFadeUp" data-wow-delay="0.2s">
                        Our Partners
                    </h2>
                </div>
                <ul className="logo-list">
                    <li className="wow fadeInUp" data-wow-delay="0.3s"><a href="#">
                      <img src="assets/img/HDFC.png" alt="saaspik client"/></a></li>
                    <li className="wow fadeInUp" data-wow-delay="0.3s"><a href="#">
                      <img src="assets/img/EXide.png" alt="saaspik client"/></a></li>
                    
                </ul>
            </div>
            
        </section>



        <Footer />
      </div>

    </>
  );
}

export default App;
